import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";

const NotFoundPage = (p) => {
  const title = "404 | Out Out Events";
  const description = "Page not found";

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={title}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:url`,
            content: p.location.href,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: ``,
          },
          {
            property: `og:image`,
            content: ``,
          },
          {
            name: "facebook-domain-verification",
            content: ``,
          },
        ]}
      />
      <div className="not-found">
        <p>Oops! This page doesn't exist.</p>
        <p>
          <Link to="/">Back to home</Link>
        </p>
      </div>
    </React.Fragment>
  );
};

export default NotFoundPage;
